.Toastify__toast-container {
  width: 85%;
  margin: 0px;
}
@media only screen and (max-width: 600px) {
  .Toastify__toast-container {
    width: 100%;
    padding: 0 24px;
  }
}

.Toastify__toast {
  min-height: auto;
  padding: 0px;
  border-radius: 8px;
}
.Toastify__toast-body {
  padding: 0px;
  margin: 0px;
}
