@tailwind components;
@tailwind utilities;
@import 'react-loading-skeleton/dist/skeleton.css';
@import 'react-toastify/dist/ReactToastify.css';

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #282c34;
  color: white; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Below code removes the default browser styles on form elements*/
input[type='number'] {
  -moz-appearance: textfield;
}
[type='button'] {
  -webkit-appearance: none;
}
/*Below code sets default cursor style on disbled button and input elements*/
button:disabled,
input:disabled {
  cursor: not-allowed;
}

.tooltip-container {
  box-shadow: 0px 10px 20px #73737333;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  position: absolute;
}

.tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
  pointer-events: none;
}

.tooltip-arrow::before {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.tooltip-arrow::after {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow {
  bottom: 0;
  margin-bottom: -1rem;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow:before {
  border-color: #d4d4d4 transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::after {
  border-color: #fff transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow {
  margin-top: -0.4rem;
  top: 0;
}
.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::before {
  border-color: transparent transparent #d4d4d4 transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::after {
  border-color: transparent transparent #fff transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow {
  margin-left: -0.7rem;
  left: 0;
}
.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::before {
  border-color: transparent #d4d4d4 transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}
.tooltip-container[data-popper-placement*='right'] .tooltip-arrow:after {
  border-color: transparent #fff transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow {
  margin-right: -0.7rem;
  right: 0;
}
.tooltip-container[data-popper-placement*='left'] .tooltip-arrow:before {
  border-color: transparent transparent transparent #d4d4d4;
  border-width: 0.5rem 0 0.5rem 0.4em;
}
.tooltip-container[data-popper-placement*='left'] .tooltip-arrow:after {
  border-color: transparent transparent transparent #fff;
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}
